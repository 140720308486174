<template>
  <div class="form-container">
    <h5 class="main-title">게시글 등록</h5>
    <div class="bs-flex">
      <h5>구분</h5>
      <mdb-select
          outline
          v-model="oneOptions"
          @getValue="getSelectOneValue"
          class="search-select"/>
    </div>
    <div class="bs-flex">
      <h5>첨부파일</h5>
      <mdb-file-input
          class="file-input-doc"
          btnTitle="문서파일 선택"
          @getValue="getFileInputValue"
          icon-right="upload"
          textFieldTitle="excel / pdf / hwp "
          sm
          accept="application/vnd.ms-excel, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .hwp"
      />
    </div>
    <div class="bs-flex">
      <h5>게시글 제목</h5>
      <input class="custom-input-box" v-model="title"/>
    </div>
    <div class="bs-flex">
      <h5>게시글 내용</h5>
      <textarea class="custom-textarea-box" cols="18" v-model="desc"/>
    </div>
    <hr/>
    <div class="bs-flex">
      <button class="btn-list" @click="back">목록으로</button>
      <button class="btn-add" @click="boardAdd">등록</button>
    </div>
  </div>
</template>

<script>

import {mdbFileInput, mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/lib/firebaseError";

export default {
  name: "DataDownloadAdd",
  components: {
    mdbSelect,
    mdbFileInput,
  },
  data() {
    return {
      fbCollection: 'reference',
      oneOptions: [
        {text: "평가지", value: "평가지", selected: true},
        {text: "평가 매뉴얼", value: "평가 매뉴얼"},
      ],
      oneOptionVal: '평가지',
      fileList: [],
      files: {},
      file: '',
      fileUrl: '',
      fileName: '',
      title: '',
      desc: '',
      regDate: '',
    }
  },
  methods: {
    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    boardAdd() {
      const self = this;
      self.regDate = firebase.firestore.Timestamp.fromDate(new Date())
      if (self.fileList.length !== 0) {
        self.onUploadFile();
      } else {
        self.onSend();
      }

    },
    onUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.fileList[0];
      const storageRef = storage.ref();

      const savePath = storageRef.child('reference/' + self.regDate + '/' + file.name);
      const upload = savePath.put(file)

      self.files = {};
      self.fileName = file.name;
      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              self.file = url;
              self.onSend();
            });
          }
      );
    },
    onSend() {
      const self = this;
      const data = {
        title: self.title,
        desc: self.desc,
        type: self.oneOptionVal,
        file: self.file,
        fileName: self.fileName,
        download: 0,
        regDate: self.regDate
      }

      firebase.firestore().collection(self.fbCollection)
          .add(data)
          .then(async () => {
            alert('등록되었습니다.');
            this.$router.go(-1)
          }).catch((err) => {
        firebaseError(err)
      });
    },
    getSelectOneValue(value) {
      this.oneOptionVal = value;
    },
    back() {
      this.$router.go(-1)
    },
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>


.form-container {
  background: white;
  padding: 41px 38px;

}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.bs-flex {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  min-width: 150px;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.dis {
  background: #F8F8FA !important;
}

.btn-flex {
  display: flex;
}

.custom-textarea-box {
  width: 100%;
  min-height: 180px;
  border-radius: 4px;
  border: 1px solid #E1E3E6 !important;
}

.custom-input-box {
  width: 800px;
}

.btn-list {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #0A1120;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-cancel {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-add {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
}

.btn-upload {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  min-width: 72px;
  height: 40px;
  background: #0A1120;
  border-radius: 4px;
  border: 0;
  margin: 0 10px;
}

.btn-del {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  min-width: 72px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  border: 0;
}


.search-select {
  margin-right: 8px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .md-form {
  width: 151px;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}
</style>


